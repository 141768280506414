/**
*
*   Service Features
*       - A dropdown for switching languages
*
**/
'use client';


import Config from '@/config';
import Button from '@/components/button';
import Image from '@/components/image';
import Storefront from '@/libraries/shopify/class';
import { gsap } from 'gsap';
import { useGSAP } from "@gsap/react";
import { useState, useEffect, useRef } from 'react';
import { useTranslations } from 'next-intl';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
//import 'swiper/css';

//Build the Element
export default function Features({ list, title, button, description , ...props }){

    const t                     = useTranslations(),
        ref                     = useRef(),
        [swiper, setSwiper]     = useState({
            instance:   null,
            realIndex:  0,
            slides:     []
        }),
        Features = {

            data: list,

            //The Swiper Settings
            Swiper: {

                //Params
                slidesPerView:  'auto',
                spaceBetween:   10,

                //Methods
                onSwiper:       swiper => setSwiper({
                    instance:       swiper,
                    localIndex:     swiper.realIndex,
                    slides:         swiper.slides
                }),

                onSlideChange: swiper => {

                    //Update Settings
                    setSwiper({
                        instance:   ( swiper ),
                        localIndex: ( swiper.realIndex ),
                        slides:     ( swiper.slides )
                    });

                }
            },


            /**
            *
            *   next
            *       - Overrides default swiper to activate each slide
            *
            *   Params:
            *       n/a
            *
            **/
            next: e => {

                //Store Next
                setSwiper({
                    instance:   ( swiper.instance ),
                    localIndex: ( swiper.localIndex + 1 ),
                    slides:     ( swiper.slides )
                });

                //Go to Next
                swiper.instance.slideNext();

            },


            /**
            *
            *   previous
            *       - Overrides default swiper to activate each slide
            *
            *   Params:
            *       n/a
            *
            **/
            previous: e => {

                //Store Next
                setSwiper({
                    instance:   ( swiper.instance ),
                    localIndex: ( swiper.localIndex - 1 ),
                    slides:     ( swiper.slides )
                });

                //Go to previous slide
                swiper.instance.slidePrev();

            },


            /**
            *
            *   goto
            *       - Overrides default swiper to activate a specific slide
            *
            *   Params:
            *       index:      (INT) The index to go to
            *
            **/
            goto: index => {

                //Store Next
                setSwiper({
                    instance:   ( swiper.instance ),
                    localIndex: index,
                    slides:     ( swiper.slides )
                });

                //Go to Next if the swiper instance is accurante, otherwise do nothing
                swiper.instance.slideTo( index );

            }
        }

    //Return
    return (
        <section className="features" ref={ ref }>
            <div className="wrap">
                { ( title || button ) && (
                    <header className="container">
                        { title && (
                            <h2>{ title }</h2>
                        )}
                        { description && (
                            <p>{ description }</p>
                        )}
                        { button && (
                            button
                        )}
                    </header>
                )}
                <div className="slider">
                    <Swiper {...Features.Swiper} className="list">
                        {
                            Features.data.map( (row,i) => {
                                return (
                                    <SwiperSlide className={ 'item ' + ( i == swiper.localIndex ? 'active' : '' ) } key={ i }>
                                        {({ isActive })=>{
                                            return (
                                                <>
                                                    <Image src={ row.image.src } alt={ row.image?.alt } width={ ( row.image?.width || 653 ) } height={ ( row.image?.height || 620 ) } />
                                                    { row.content && (
                                                        <>
                                                            <Button onClick={ () => Features.goto( i ) } type="white">{ row.content.title }</Button>
                                                            <div className="content">
                                                                <h3>{ row.content.title }</h3>
                                                                <p>{ row.content.description }</p>
                                                            </div>
                                                        </>
                                                    )}
                                                    { row.product && row.product?.title && (
                                                        <>
                                                            <Button onClick={ () => Features.goto( i ) } type="white">
                                                                { row.product.title } 
                                                                &nbsp;-&nbsp; 
                                                                { row.product?.variant?.compareAtPrice?.amount > 0 ? (<strike className="was">${ Storefront.money( row.product.variant.compareAtPrice.amount ) }</strike> ) : null }
                                                                ${ Storefront.money( row.product?.variant?.price?.amount || row.product?.price ) }
                                                            </Button>
                                                            <div className="content">
                                                                <h3>
                                                                    { row.product.title } 
                                                                    &nbsp;-&nbsp; 
                                                                    { row.product?.variant?.compareAtPrice?.amount > 0 ? (<strike className="was">${ Storefront.money( row.product.variant.compareAtPrice.amount ) }</strike> ) : null }
                                                                    ${ Storefront.money( row.product?.variant?.price?.amount || row.product?.price ) }
                                                                </h3>
                                                                <p>{ row.product.description }</p>
                                                                <div className="buttons">
                                                                    <Button href={ row.product?.url || ( '/products/' + row.product.handle ) } type="dark" inverted>{ t('Navigation.shop-now') }</Button>
                                                                    <Button onClick={ () => Features.previous() } hide={ i == 0 } arrow="reverse">{ t('Navigation.prev') }</Button>
                                                                    <Button onClick={ () => Features.next() } hide={ i == ( swiper.slides.length - 1 ) } arrow>{ t('Navigation.next') }</Button>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                </>
                                            )
                                        }}
                                    </SwiperSlide>
                                )
                            })
                        }
                    </Swiper>
                    <nav>
                        <Button onClick={ () => Features.previous() } hidden={ swiper.localIndex == 0 }>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="19" viewBox="0 0 20 19" fill="none">
                                <path d="M10.6317 1.81006L18.4803 9.65863L10.6317 17.5072" stroke="white" strokeWidth="1.85526" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M18.4802 9.65869L1.78296 9.65869" stroke="white" strokeWidth="1.85526" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </Button>
                        <Button onClick={ () => Features.next() } hidden={ swiper.localIndex == ( swiper.slides.length - 1 ) }>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="19" viewBox="0 0 20 19" fill="none">
                                <path d="M10.6317 1.81006L18.4803 9.65863L10.6317 17.5072" stroke="white" strokeWidth="1.85526" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M18.4802 9.65869L1.78296 9.65869" stroke="white" strokeWidth="1.85526" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </Button>
                        <ul>{
                            Features.data.map( (row,i) => {
                                return (
                                    <li key={ i } className={ ( swiper.localIndex == i ? 'active' : '' ) } onClick={ () => Features.goto( i ) }>

                                    </li>
                                )
                            })
                        }</ul>
                    </nav>
                </div>
            </div>
        </section>
    );

}