/**
*
*   Home Introduction
*       - A text looping word
*
**/
'use client';


import Config from '@/config';
import Button from '@/components/button';
import Image from '@/components/image';
import { useState, useEffect, useRef } from 'react';
import { useTranslations } from 'next-intl';
//import 'swiper/css';

//Build the Element
export default function HomePress( props ){

    const t = useTranslations();

    //Return
    return (
        <section id="introduction">
            <div className="container">
                <h2>{ t('Pages.Home.introduction.title') }</h2>
                <p>{ t('Pages.Home.introduction.subhead') }</p>
            </div>
        </section>
    );

}